<script>
import { Line} from 'vue-chartjs'

export default {
  extends: Line,
 
  data() {
    return {
      gradient1: null,
      gradient2: null,

      // Default options
      default_options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
      }
    }
  },

  props: ['chartData', 'options'],
  
  computed: {
    // Merge default options with provided options.
    chart_options: function() { 
      return Object.assign({}, this.default_options, this.options);
    },
    chart_data: function() { // SHOULD NOT BE DONE THIS WAY - START SOMEWHERE HUH?
      let data = Object.assign({}, this.chartData);

      if ( data?.datasets[0] ) { 
        data.datasets[0].backgroundColor = this.gradient2;
        data.datasets[0].borderColor = '#05CBE1';
        data.datasets[0].borderWidth = 1;
      }
      if ( data?.datasets[1] ) { 
        //data.datasets[1].backgroundColor = this.gradient2;
        data.datasets[1].borderColor = '#FC2525';
        data.datasets[1].borderWidth = 1;        
      }

      return data;
    }
  },
  
  mounted () {
    this.gradient1 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    this.gradient1.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient1.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient1.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    this.renderChart(this.chart_data, this.chart_options);
  }
}
</script>